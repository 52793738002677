
<template>
  <div>
    <!-- :headTitle="'留言 [订单号：' + orderList.order_no + ']'" -->
    <el-dialog
      :title="lang_data == 1 ? '消息列表' : 'The message list'"
      :visible="msgboxShow"
      :before-close="cancel"
      width="900px"
      :close-on-click-modal="false"
      class="msgbox"
    >
      <div
        class="messageDwCont"
        v-loading="loading"
        :element-loading-text="lang_data == 1 ? '拼命加载中' : 'Loading'"
      >
        <template v-if="orderList.length > 0 && !loading">
          <div class="messageLeft" v-if="!orderids">
            <ul>
              <li
                v-for="(item, i) in orderList"
                :key="item.id"
                :class="{
                  selected: index == i,
                  color: item.message_is_read == 1,
                }"
                @click="clickLi(item, i)"
              >
                <span class="unread" v-if="item.message_is_read == 0"></span>
                <b>{{ item.send_msg_name }}</b>
                <span>
                  [{{ item.order_no ? item.order_no : item.order_code }}]

                  <i @click.stop="iconClick(item)">详情</i>
                </span>
              </li>
            </ul>
          </div>
          <div class="message-group">
            <el-empty
              style="overflow: auto; flex: 1"
              description="暂无消息"
              v-if="messageList.length == 0"
            ></el-empty>
            <div ref="messageCont" class="message-content" v-else>
              <div
                class="message-item"
                ref="noteListItem"
                v-for="(item, i) in messageList"
                :key="i"
                :class="[item.type == 1 ? 'saler' : 'factory']"
              >
                <div class="message-user-icon">
                  <img
                    v-if="item.type == 2"
                    :src="
                      (item.crm_staff_info && item.crm_staff_info.photo) ||
                      imgSrc
                    "
                    alt=""
                  />

                  <img
                    v-else
                    :src="
                      (item.doctor_info && item.doctor_info.photo) || imgSrc
                    "
                    alt=""
                  />
                </div>
                <div class="message-context-box">
                  <p class="message-content-header">
                    <span class="message-content-user">{{
                      item.doctor_info.true_name ||
                      item.crm_staff_info.true_name
                    }}</span>
                    <span class="message-content-time">{{
                      item.add_time
                    }}</span>
                  </p>
                  <p class="message-content-text">
                    <!-- <i class="el-icon-edit edit" title="编辑消息"  v-if="userId==item.crm_staff_info.crm_staff_id" @click="editMsg(item)"></i>
                                <i class="el-icon-close message-delete-icon" title="删除消息" @click="deleteMessage(item.id)" v-if="userId==item.crm_staff_info.crm_staff_id"></i> -->
                    <span v-html="item.content"></span>
                  </p>
                </div>
              </div>
            </div>

            <div class="message-footer">
              <input
                type="file"
                ref="uploadBtn"
                @change="onSelectFile"
                id="uploadImg"
                style="display: none"
                accept="image/*"
              />
              <quill-editor
                v-model="sendText"
                ref="myquillEditor"
                :options="editorOption"
                class="editor"
              >
              </quill-editor>
              <div class="message-footer-send-btn">
                <el-button
                  :disabled="!sendText"
                  @click="sendMessage"
                  type="primary"
                  size="small"
                  >{{ lang_data == 1 ? "发送" : "Send" }}</el-button
                >
                <el-image
                  style="
                    width: 10px;
                    height: 10px;
                    margin-right: -20px;
                    padding-left: 10px;
                  "
                  ref="imgs"
                  :src="src"
                  :preview-src-list="srcList"
                >
                </el-image>
              </div>
            </div>
          </div>
        </template>
        <el-empty
          style="width: 100%"
          v-if="orderList.length <= 0 && !loading"
          :description="lang_data == 1 ? '暂无消息' : 'Temporarily no data'"
        ></el-empty>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
export default {
  name: "Msgbox",
  components: { quillEditor },
  async created() {
    this.loading = true;
    await this.unreadList(1);
    if (this.orderList.length > 0) {
      this.order_id = this.orderList[0].id;
      await this.getlist();
      await this.setReaded(this.orderList[0]);
      if (!this.orderids) {
        this.orderList[0].message_is_read = 1;
      }
      await this.getunreadlist();
    }
    setTimeout(() => {
      this.loading = false;
    }, 500);
  },
  props: {
    orderids: [Array, Number, String],
    msgboxShow: [Boolean],
  },
  watch: {
    msessageData(data) {
      this.receivingDisplay(data);
    },
  },

  computed: {
    msessageData() {
      return this.$store.state.wsUnreadData;
    },
  },
  data() {
    return {
      imgSrc: require("@/assets/img/teeth.png"),
      lang_data: localStorage.lang_data || 1,
      messageList: [],
      sendText: "",
      //富文本配置
      editorOption: {
        placeholder: "",
        theme: "snow", // or 'bubble'
        modules: {
          toolbar: {
            container: [["bold", "italic", "underline"], ["image"]],
            handlers: {
              image: function (value) {
                if (value) {
                  document.querySelector("#uploadImg").click();
                } else {
                  this.quill.format("image", false);
                }
              },
            },
          },
        },
      },
      index: 0,
      order_id: "",
      orderList: "",
      loading: true,
      heartbeatTimer: null,
      src: "",
      srcList: [],
    };
  },
  methods: {
    async receivingDisplay(data) {
      // 如果接收的消息正好是当前打开的订单聊天窗口
      if (this.order_id == data.order_id) {
        await this.setReaded({ message_is_read: 0 });
        await this.getunreadlist();
        this.sendText = ""; //清空输入框
        var pushlist = { doctor_info: {}, type: data.type || 1 }; //插入一条
        pushlist.add_time = this.$moment(new Date()).format(
          "YYYY-MM-DD  HH:mm:ss"
        );
        pushlist.content = data.content;
        pushlist.doctor_info.true_name =
          data.doctor_name || data.crm_staff_name;
        pushlist.doctor_info.photo = JSON.parse(localStorage.userData).photo;
        this.messageList.push(pushlist);
        this.messageList.forEach((v) => {
          v.content = this.insertEvent(this.showHtml(v.content));
        });

        this.$nextTick(() => {
          setTimeout(() => {
            this.imgAddEvent();
          }, 500);
          if (this.$refs.messageCont) {
            this.$refs.messageCont.scrollTop = 99999; //滚动条始终保持在底部
          }
        });
      } else {
        // 先判断有列表内有没有，有就标记未读
        this.orderList.forEach((item) => {
          if (item.id == data.order_id) {
            item.message_is_read = 0;
          }
        });
        // 如果列表没有 刷新列表
        let res = this.orderList.some((item) => {
          if (item.id == data.order_id) {
            return true;
          }
        });
        if (!res) {
          await this.unreadList(0);
          // 当前没有数据，刷新列表后要请求详情和标记已读
          if (!this.order_id) {
            this.order_id = this.orderList[0].id;
            await this.getlist();
            await this.setReaded(this.orderList[0]);
            this.orderList[0].message_is_read = 1;
          }
        }
      }
    },
    async getunreadlist() {
      return this.$axios
        .post("/orders/order_message/getunreadlist")
        .then((res) => {
          if (res.code != 1) {
            this.$message.error(res.msg);
            return;
          }
          this.$store.dispatch("msessageMethod", {
            num: res.data.length,
            arr: res.data,
          });
        });
    },
    async unreadList(val) {
      return this.$axios
        .post("/orders/orders/getList", {
          is_comment: this.orderids ? 2 : 1,
          is_comment_sort: val,
          page_size: 1000,
          current_page: 0,
          order_ids: this.orderids,
        })
        .then((res) => {
          if (res.code != 1) {
            this.$message.error(res.msg);
            return;
          }
          this.orderList = res.data.list;
        });
    },
    // 点击列表
    async clickLi(item, i) {
      this.index = i;
      this.order_id = item.id;
      await this.getlist();
      await this.setReaded(item);
      this.getunreadlist();
      item.message_is_read = 1;
    },
    iconClick(item) {
      console.log(item);
      if (item.order_type == 1) {
        this.$router.push(
          {
            path: "/orderDetails",
            query: { id: item.id },
          },
          () => {}
        );
      } else {
        this.$router.push(
          {
            path: "/afterSalesDetails",
            query: {
              rebackid: item.id,
              id: item.order_reback_id,
            },
          },
          () => {}
        );
        localStorage.menuIndex = 1;
      }
      this.$emit("cancel");
    },
    // 消息标记已读
    async setReaded(item) {
      if (item.message_is_read == 0) {
        return this.$axios
          .post("/orders/easemob/setRead", {
            order_id: this.order_id,
          })
          .then(() => {});
      }
    },
    // 获取聊天数据
    async getlist() {
      let params = {
        page_size: 1000,
        current_page: 1,
        order_id: this.order_id,
      };
      return this.$axios
        .get("/orders/order_message/getmessagelist", { params: params })
        .then((res) => {
          if (res.code != 1) {
            this.$message.error(res.msg);
            return;
          }
          this.messageList = res.data.list.reverse();
          this.messageList.forEach((v) => {
            v.content = this.insertEvent(this.showHtml(v.content));
          });
          this.$nextTick(() => {
            setTimeout(() => {
              this.imgAddEvent();
            }, 1000);
            let time = setInterval(() => {
              if (this.$refs.messageCont) {
                this.$refs.messageCont.scrollTop = 99999;
                clearTimeout(time);
              }
            }, 300);
          });
        });
    },
    //解码
    showHtml(html) {
      return html
        .replace(html ? /&(?!#?\w+;)/g : /&/g, "&amp;")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&quot;/g, '"')
        .replace(/&#39;/g, "'");
    },
    //给图片添加class,样式
    insertEvent(oristr) {
      var insertStr = ' class="bigphoto" style="height: 100px;" ';
      var str = oristr;
      var n = 0;
      while (str.indexOf("<img", n) != -1) {
        var m = str.indexOf("<img", n);
        str = str.slice(0, m + 4) + insertStr + str.slice(m + 4);
        n = m + 1;
      }
      return str;
    },
    bigPhoto(src) {
      this.allPhoto = [];
      this.photo = {
        open: true,
        img_id: 1,
      };
      this.allPhoto.push({ url: src, img_id: 1 });
      this.$emit("viewnoteBigphoto", this.allPhoto, this.photo);
    },
    imgAddEvent() {
      var _this = this;
      document.querySelectorAll(".bigphoto").forEach((item) => {
        item.addEventListener("click", function () {
          _this.src = item.src;
          _this.srcList = [item.src];
          setTimeout(() => {
            _this.$refs.imgs.$el.children[0].click();
          });
        });
      });
    },
    upload(file) {
      return new Promise((resolve) => {
        this.$getuploadsign(4, file).then((res) => {
          if (res.res.code != 1) {
            this.$message(res.res.msg);
            return;
          }
          resolve(res.url);
        });
      });
    },
    // 发送消息
    sendMessage() {
      this.$store.commit(
        "messageSending",
        JSON.stringify({
          content: this.sendText,
          order_id: this.order_id,
          message_type: "send_order_message",
          token: localStorage.token,
        })
      );
    },
    async onSelectFile(e) {
      let files = e.target.files;
      if (files.length == 0) return;
      let url = await this.upload(files[0]);
      let quill = this.$refs.myquillEditor.quill;
      let length = quill.getSelection().index;
      quill.insertEmbed(length, "image", url);
      quill.setSelection(length + 1);
      e.target.value = "";
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>
<style lang="scss">
.messageDwCont {
  width: 100%;
  height: 560px;
  display: flex;
  .message-group {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    .loading-box {
      flex: 1;
      text-align: center;
      font-size: 30px;
      color: #888;
      padding-top: 25%;
    }
    .message-content {
      flex: 1;
      padding: 15px;
      overflow: auto;
      border-bottom: 1px solid #ddd;
      .message-item {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        .message-user-icon {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
        .message-context-box {
          flex: 1;
          .message-content-header {
            display: flex;
            color: #888;
            .message-content-user {
              padding: 0 5px;
            }
          }
          .message-content-text {
            max-width: 90%;
            display: inline-block;
            color: #222222;
            box-shadow: 0px 1px 3px 1px #e2e2e2;
            padding: 8px 15px 8px 15px;
            margin: 2px 0 10px 0;
            border-radius: 5px;
            word-break: break-all;
            text-align: left;
            position: relative;
            img {
              max-width: 100%;
            }
            .message-delete-icon,
            .edit {
              position: absolute;
              top: 5px;
              right: 5px;
              color: #888;
              cursor: pointer;
              display: none;
              &:hover {
                color: #333;
              }
            }
            .edit {
              right: 25px;
            }
            &:hover {
              .message-delete-icon,
              .edit {
                display: block;
              }
            }
          }
        }
        &.saler {
          flex-direction: row-reverse;
          .message-context-box {
            text-align: right;
            padding-right: 5px;
            .message-content-header {
              flex-direction: row-reverse;
            }
            .message-content-text {
              padding: 8px 15px 8px 15px;
            }
          }
        }
        &.factory {
          .message-context-box {
            text-align: left;
            padding-left: 5px;
          }
        }
      }
    }
    .message-footer {
      width: 100%;
      // padding: 0 20px 10px 20px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      .message-footer-textarea {
        width: 100%;
        flex: 1;
        border: 1px solid #ddd;
        border-bottom: 0;
        padding: 10px;
      }
      .editor {
        padding: 10px 10px 0;
        flex: 1;
      }
      .message-footer-send-btn {
        border-top: 0;
        text-align: right;
        padding: 5px 10px;
        a {
          color: #a9a9a9;
          text-decoration: none;
          &:hover {
            color: #418fffe6;
          }
        }
      }
    }
  }
  .messageLeft {
    width: 30%;
    border-right: 1px solid #ddd;
    overflow: auto;
    flex: none;
    ul {
      li {
        padding: 10px 5px 10px 20px;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        position: relative;
        border-bottom: 1px solid #ddd;
        .order {
          color: #666;
        }
        .unread {
          width: 6px;
          height: 6px;
          background: #f56c6c;
          position: absolute;
          top: 10px;
          left: 12px;
          border-radius: 50%;
        }
      }
      .color {
        color: #999;
        i {
          color: #009a83;
          font-size: 12px;
        }
      }
      li:hover {
        // color: #fff;
        background: #bdd9e6;
        i {
          color: #009a83 !important;
        }
      }
      .selected {
        background: #009a83;
        color: #fff;
        i {
          color: #fff !important;
        }
      }
    }
  }
  .noscroll {
    overflow-y: visible;
    background: #fff;
  }
}
</style>
<style lang="scss">
.msgbox {
  .ql-container {
    height: 108px !important;
  }
  .el-dialog__body {
    padding: 0 !important;
  }
  .ql-editor {
    p {
      img {
        height: 100px;
        margin-right: 5px;
      }
    }
    em {
      font-style: italic;
    }
  }
}
</style>